@import "themes-override/common-theme.less";

.mat-form-field {
  width: 100%;
}

.mat-form-field-suffix {
  padding-right: 10px;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field-label:not(.mat-form-field-empty) > mat-label,
.mat-form-field.mat-focused .mat-form-field-label > mat-label {
  margin-left: 5px;
}

.mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  font-size: 90%;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.59375em) scale(1);
  width: 100%;
}

.mat-list-option,
.mat-nav-list .mat-list-item,
.mat-action-list .mat-list-item {
  &:hover {
    background-color: unset !important;
  }
  &:focus {
    background-color: unset !important;
  }
  & .mat-list-text {
    padding-left: 10px !important;
  }
}

.mat-list-item-disabled {
  background-color: unset !important;

  & .mat-list-text {
    color: @disabled !important;
  }
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: @disabled !important;
}

.mat-list-base .mat-list-option {
  font-size: unset !important;
}

.mat-stroked-button:hover {
  background-color: @selected-btn-bg-hover-colour;
}

.mat-flat-button:hover:enabled {
  background: @selected-btn-bg-hover-colour !important;
}

.mat-action-row {
  justify-content: flex-start !important;
  padding-left: 10px !important;
}

.mat-expansion-panel{
  padding-left: 5px;
  padding-right: 5px;
}

.mat-form-field-suffix {
  padding-right: 0px;
}

.mat-optgroup-label {
  height: 24px !important;
}

.mat-optgroup .mat-option:not(.mat-option-multiple){
  padding-left: 20px !important;
  height: 30px;
}

.mat-autocomplete-panel{
  max-height: 400px;
}

.mat-optgroup-label{
  padding-top: 5px !important;
}
